body {
  background-color: #0e1117;
  color: azure;
  font-size: 16px;
}

.App {
  min-height: 100vh;
}

.App-header {
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin); 
  color: white;
}

.yaml-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.yaml-output {
  flex: 1;
  margin-top: 15px;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.copy-button i {
  color: #FFF;
  font-size: 20px;
  cursor: pointer;
}

i .copy-wording {
  color: aqua;
}

.language-indicator {
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #007bff;  */
  color: white; 
  padding: 5px 10px;
}