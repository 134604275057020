@import '~font-awesome/css/font-awesome.css';

.textbox-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 7px;
}

.textbox-container {
    display: flex;
    flex-direction: row;
    background-color: #262730;
    padding: 10px;
    border-radius: 7px;
    min-width: 90vh;
    margin-bottom: 20px;
    align-items: center;
    min-height: auto;
}

.textbox-input-container {
    flex: 1;
}

.textbox-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 10px;
    background-color: #262730;
    color: #fff;
    word-wrap: break-word;
    overflow: hidden;
    resize: none;
}

.send-button {
  background-color: #ffbd44;
  color: #262730;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin-left: 10px;
  font-size: 1.3em;
  flex-direction: row;
  align-items: center;
}

.new-chat-button {
  background-color: #ffbd44;
  color: #262730;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin-left: 20px;
  font-size: 1.3em;
  align-items: center;
  margin-top: 18px;
}

.send-button i {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 5px; */
  flex-direction: row;
}

@media (max-width: 3500px) {
  .textbox-container {
    min-width: 40%; 
  }
}

@media (max-width: 2560px) {
  .textbox-container {
    min-width: 70%; 
  }
}

@media (max-width: 1440px) {
  .textbox-container {
    min-width: 70%; 
  }
}

@media (max-width: 1024px) {
  .textbox-container {
    min-width: 80%; 
  }
}
