.message-list {
    align-self: flex-start;
    font-size: 1em;
    margin-bottom: 15%;
}

.user-message {
    display: flex;
    flex-direction: row;
    background-color: #1a1c24;
    padding: 10px;
    margin-bottom: 2%;
    padding: 1em;
    border-radius: 10px;
    margin-bottom: 0.5em;
    align-items: center;
    gap: 1em;
}

.bot-message {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 2%;
    padding: 1em;
    margin-bottom: 0.5em;
    align-items: center;
    gap: 1em;
}

.message-icon-user {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffbd44;
    color: #0e1117;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.3em;
    width: 1.2em;
    height: 1.2em;
}

.message-icon-bot {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fe6c6c;
    color: #0e1117;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.3em;
    width: 1.2em;
    height: 1.2em;
}

@media (max-width: 3500px) {
    .message-list {
        margin-left: 30%;
        margin-right: 15%;
    }
  }

@media (max-width: 2560px) {
    .message-list {
        margin-left: 15%;
        margin-right: 15%;
    }
  }

@media (max-width: 1440px) {
    .message-list {
        margin-left: 10%;
        margin-right: 10%;
    }
  }

@media (max-width: 1024px) {
    .message-list {
        margin-left: 10%;
        margin-right: 10%;
    }
}   
  
